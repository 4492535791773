import React, { memo } from 'react';

import styled, { keyframes } from 'styled-components';
import { background } from '../util/theme';

const highlightSize = 0.5;

const itemShine = keyframes`
    to {
        background-position:
            200% 0, /* move highlight to right */
            0 0;
    }
`;

interface ShimmerStyles {
    size: number;
    rows: number;
    spacing: number;
    radius: number;
}

const Placeholder = styled.div<ShimmerStyles>`
    width: 100%;
    height: ${props => props.size}px; /* change height to see repeat-y behavior */
    border: 0;
    background-image: 
        linear-gradient(100deg, rgba(255, 255, 255, 0), ${background} 50%, rgba(255, 255, 255, 0) 100%),
        linear-gradient(rgba(105, 105, 105, 0.15) ${props => props.size}px, transparent 0);
    background-size: 
        ${highlightSize * 100}% 200px, /* highlight */ 
        100% ${props => props.size + props.spacing}px; /* background */
    background-position: 
        ${-(highlightSize * 2) * 100}% 0, /* highlight */ 
        0 0; /* background */
    animation: ${itemShine} 1s ease infinite;
    border-radius: ${props => props.radius}px;
    background-repeat: repeat-y;
    margin-bottom: ${props => props.spacing}px;
    
    &:last-child {
        margin-bottom: 0;
    }
`;

interface Props {
    size?: number;
    rows?: number;
    spacing?: number;
    radius?: number;
    className?: string;
}

const Shimmer: React.FC<Props> = ({ size = 16, rows = 5, spacing = 16, radius = 0, className }) => {
    const renderRows = () => {
        const output: any[] = [];

        for (let i = 0; i < rows; i++) {
            output.push(<Placeholder key={i} size={size} rows={rows} spacing={spacing} radius={radius} />)
        }

        return output;
    }
    return (
        <div className={className}>
            {renderRows()}
        </div>
    );
};

export default memo(Shimmer);
