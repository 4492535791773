import axios from "axios";

export const handleError = (err, handler?) => {
    if (err.response) {
        if (handler) {
            handler()
        }
        console.error(err);
        // client received an error response (5xx, 4xx)
    } else if (err.request) {
        // client never received a response, or request never left
        console.error(err);
    } else {
        // anything else
        console.error(err);
    }
}

const instance = axios.create({
    baseURL: process.env.PAULIANO_API_URL
});

instance.interceptors.request.use(
    config => {
        if (!config.baseURL) return config;

        const { origin } = new URL(config.baseURL);
        const allowedOrigins = [
            'http://localhost:3000',
            'https://dev.pauliano.com',
            'https://staging.pauliano.com',
            'https://api.pauliano.com',
        ];

        if (allowedOrigins.includes(origin)) {
            const token = localStorage.getItem('token');
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    error => Promise.reject(error)
)

export default instance;

