import { Alert } from 'antd';
import Shimmer from './Shimmer';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import { LiteProduct, Product } from '../types/types';
import { Button } from '@material-ui/core';
import ProductDownload from './ProductDownload';
import axios, { handleError } from '../util/axios';
import { AuthContext } from './context/AuthContext';
import { modularScale } from '../util/modularScale';
import React, { useContext, useEffect, useState } from 'react';

const ProductList = styled.div`
	padding: 1rem;
	margin: 0 auto 6rem;
	width: 100%;
	max-width: 600px;
`;

const Title = styled.h1`
	font-size: ${modularScale(1)};
	font-weight: 400;
    text-align: center;
    
    @media (min-width: 900px) {
        font-size: ${modularScale(2)};
    }
`;

const Container = styled.div`
	padding: 1rem;
`;

const Card = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    padding: 1rem;
    border-radius: 4px;
    margin: 0 auto 2rem;
	width: 100%;
	max-width: 600px;
	
	@media (min-width: 900px) {
        padding: 2rem;
    }
`;

const Info = styled.div`
`;

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const StyledButton = styled(Button)`
	color: #fff;
	
	&:hover, &:focus, &:active {
	    color: #fff;
	}
`;

const MyProducts: React.FC = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState<Product[]>([]);
    const [error, setError] = useState('');

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login?redirect=' + encodeURIComponent('/my-products'));
            return;
        }

        const getProducts = async () => {
            try {
                const response = await axios.get('/products');
                const products = response.data;

                setProducts(products);
            } catch (err) {
                handleError(err, () => {
                    setError(err.message);
                });
            }

            setIsLoading(false);
        }

        getProducts();
    }, [isAuthenticated]);

    const renderProducts = () => {
        if (isLoading) {
            return (
                <ProductList>
                    <Shimmer size={60} spacing={16} rows={4} radius={10}/>
                </ProductList>
            )
        }

        if (error) {
            const message = 'An error occurred loading your products. ' +
                'Please refresh the page to try again. If the problem ' +
                'persists, please contact support.';

            return (
                <ProductList>
                    <Alert message={message} type="error" />
                </ProductList>
            )
        }

        if (products.length > 0) {
            return (
                <ProductList>
                    {products.map((product, i) =>
                        <ProductDownload key={i} order={i + 1} product={{ id: product.productId, ...product}}/>
                    )}
                </ProductList>
            );
        }

        return (
            <Container>
                <Card>
                    <Info>
                        Looks like you don't have any sheets available for download.
                        Browse the Pauliano collection to get started!
                    </Info>
                </Card>
                <ButtonContainer>
                    <StyledButton component={Link} to={'/sheets'} variant="contained" color="primary">
                        Browse collection
                    </StyledButton>
                </ButtonContainer>
            </Container>
        )
    }

    return (
        <div>
            <Title>Your Sheets</Title>
            {renderProducts()}
        </div>
    )
};

export default MyProducts;
