import Icon from '@mdi/react';
import Spinner from './Spinner';
import { grey } from '../util/theme';
import styled from 'styled-components';
import React, { useState } from 'react';
import Download from './icons/Download';
import { LiteProduct, Product } from '../types/types';
import { Button } from '@material-ui/core';
import { mdiCheckCircleOutline } from '@mdi/js';
import axios, { handleError} from '../util/axios';
import { modularScale } from '../util/modularScale';
import { CSSTransition } from 'react-transition-group';

const ANIM_DELAY = 50;

const Container = styled.div<{ order }>`
    display: flex;
    align-items: center;
	background-color: #fff;
	margin-bottom: 1rem;
	border-radius: 10px;
	height: 60px;
	width: 100%;
	animation-delay: ${props => props.order * ANIM_DELAY}ms;
	box-shadow: 0 3px 11px -5px rgba(0, 0, 0, 0.18);
`;

const Title = styled.div`
    font-size: ${modularScale(0)};
	font-weight: bold;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	flex-grow: 5;
	flex-shrink: 1;
	
	@media (min-width: 900px) {
	    font-size: ${modularScale(0)};
        
        &:after {
            content: '•';
            color: ${grey};
            margin: 0 0.5rem;
        }
	}
`;

const Subtitle = styled.div`
    font-size: ${modularScale(-1)};
    color: ${grey};
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	flex-grow: 1;
	flex-shrink: 4;
	
	@media (min-width: 900px) {
	    font-weight: 400;
        font-size: ${modularScale(-1)};
	    order: 2;
	}
`;

const Red = styled.div`
	color: red;
	line-height: 1.2;
`;

const Info = styled.div`
	padding: 1rem;
	overflow: hidden;
	
	@media (min-width: 900px) {
	    display: flex;
	    align-items: center;
	}
`;

const DownloadButton = styled(Button)`
    color: #fff;
    display: flex;
    align-items: center;
    margin-left: auto;
    border-radius: 0 10px 10px 0;
    height: 100%;
    padding: 0;
    
    .MuiButton-label {
        flex-direction: column;
    }

	svg {
	    width: 24px;
	}
	
	span {
	    font-size: ${modularScale(-2)};
	    letter-spacing: 1px;
	}
`;

interface Props {
    product: LiteProduct;
    order?: number;
}

const ProductDownload: React.FC<Props> = ({ product, order = 0 }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [error, setError] = useState('');

    const onClickDownload = async () => {
        setIsLoading(true);
        setError('');

        try {
            await axios.post('/product/download', { id: product.id });
            setIsLoading(false);
        } catch (err) {
            return handleError(err, () => {
                setError(err.message);
                setIsLoading(false);
            })
        }

        setIsFinished(true);
    }

    const renderButtonContents = () => {
        if (isLoading) {
            return <Spinner color='#fff'/>;
        }

        if (isFinished) {
            return <Icon path={mdiCheckCircleOutline} />
        }

        return (
            <>
                <Download />
                <span>PDF</span>
            </>
        )
    }

    const renderInfo = () => {
        if (error) {
            return (
                <Info>
                    <Red>
                        An error occurred. Please try again, or
                        contact support if the problem persists.
                    </Red>
                </Info>
            )
        }

        return (
            <Info>
                <Subtitle>
                    {product.subtitle}
                </Subtitle>
                <Title>
                    {product.title}
                </Title>
            </Info>
        )
    }

    return (
        <CSSTransition
            timeout={220 + (order * ANIM_DELAY)}
            classNames={'checkout'}
            unmountOnExit={true}
            appear={true}
            in={true}
        >
            <Container order={order}>
                {renderInfo()}
                <DownloadButton
                    component={'a'}
                    href={product.download_url}
                    onClick={onClickDownload}
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={isLoading || isFinished}
                    download
                >
                    {renderButtonContents()}
                </DownloadButton>
            </Container>
        </CSSTransition>
    );
};

export default ProductDownload;
