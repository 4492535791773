import React from 'react';
import Layout from '../components/layouts/Layout';
import MyProductsComponent from '../components/MyProducts';
import SEO from '../components/seo/SEO';

const MyProducts: React.FC = () => {
    return (
        <Layout customSEO>
            <SEO title={'My Sheets'} />
            <MyProductsComponent />
        </Layout>
    );
};

export default MyProducts;
